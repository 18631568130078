import { PageLayout } from "src/componentsV2/PageLayout";
import { useGetExploJWTToken } from "../../queries/useGetExploJWTToken";

function ExploDashboard() {
  const { data } = useGetExploJWTToken();

  return (
    <PageLayout title="Reporting Dashboard">
      {data && data.token ? (
        <iframe
          src={`https://app.explo.co/iframe/${data.token}`}
          style={{
            width: "100%",
            border: "none",
            height: "100vh",
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </PageLayout>
  );
}

export default ExploDashboard;
